import React from 'react'
import Helmet from 'react-helmet'

import tracery from 'tracery-grammar'

import Layout from '../components/layout'

import './index.scss'

let grammar = tracery.createGrammar({
  animal: ['panda', 'fox', 'capybara', 'iguana'],
  emotion: ['sad', 'happy', 'angry', 'jealous'],
  origin: ['i am #emotion.a# #animal#.'],
})
grammar.addModifiers(tracery.baseEngModifiers)

const IndexPage = ({ data }) => [
  <Helmet
    // bodyAttributes={{
    //   class: 'home',
    // }}
    key="helmet"
  />,
  <Layout key="layout">
    <p>sjd.is</p>
  </Layout>,
]

export default IndexPage
