import React from 'react'

class Header extends React.Component {
  render() {
    return (
      <header className="header" role="banner">
        <p>sjd.is</p>
      </header>
    )
  }
}

export default Header
